<template>
  <v-dialog
    v-model="modalOpen"
    content-class="dialog-tooltip"
    transition="dialog-bottom-transition"
    max-width="100%"
    lazy
  >
    <v-card>
      <v-card-text
        v-if="content"
        class="dialog-show-checklists-content"
        v-dompurify-html="content"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from '@/mixins/dialogMixin.js'

export default {
  name: 'DialogShowTooltip',
  mixins: [dialogMixin],
  props: {
    data: {
      type: Object,
      required: true
    },
    cell: {
      type: Object,
      required: true
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    text() {
      let text = this.content || ''
      const variables = text.match(/@[a-z_0-9]+@/gi)
      const variablesData = this.data.variables || {}
      if (Array.isArray(variables)) {
        for (let variable, i = 0; (variable = variables[i++]);) {
          text = text.replace(new RegExp(variable, 'gi'), variablesData[variable])
        }
      }
      return text
    }
  }
}
</script>

<style>
  .v-dialog.dialog-tooltip {
    width: unset;
  }
</style>
