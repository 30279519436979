<template>
  <div>
    <v-btn
      v-if="visible"
      :class="`${classes}`"
      :disabled="disabled"
      title="Tooltip"
      type="button"
      small
      @click="dialog.active = true"
    >
      <v-icon>
        mdi-information
      </v-icon>
    </v-btn>
    <dialog-show-tooltip
      v-if="!disabled"
      v-model="dialog.active"
      :content="cell.tooltip"
      :cell="cell"
      :data="data"
    />
  </div>
</template>

<script>
import DialogShowTooltip from '@/components/ChecklistBuilder/Dialog/ShowTooltip.vue'

export default {
  name: 'ChecklistCellButtonTypeDisplayTooltip',
  components: {
    DialogShowTooltip
  },
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      dialog: {
        active: false
      }
    }
  },
  computed: {
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    visible() {
      return !!this.cell.tooltip || !!this.cell.settings.tooltip_image_data
    }
  }
}
</script>
